@import "../../../../../scss/_imports.scss";

@keyframes borderfadein {
  0% {
    // background: #ffffff;
    border: 6px solid rgb(255, 255, 255);
    // box-shadow: 0px 0px 0px 2px #ffffff;
  }

  50% {
    // background: #fbeae1;
    border: 6px solid rgb(255, 209, 78);
    // box-shadow: 0px 0px 0px 2px #e77238;
  }

  75% {
    // background: #fbeae1;
    border: 6px solid rgb(255, 209, 78);
    // box-shadow: 0px 0px 0px 2px #e77238;
  }

  100% {
    // background: #ffffff;
    border: 6px solid rgb(255, 255, 255);
    // box-shadow: 0px 0px 0px 2px #ffffff;
  }
}

.new-item {
  .list-item__content {
    animation: borderfadein 4s ease-in-out;
    border: 6px solid white;
  }
}

.listing-item__title {
  margin: 0 0 14px;

  color: #505050;
  font-family: $playfair;
  font-size: 22px;
  font-weight: 700;
  line-height: 35px;
}

.listing-item__description {
  padding-right: 70px;

  line-height: 25px;
}

.listing-item {
  position: relative;
  padding: 0;

  .list-item__content {
    max-height: 1000px;
    transition: all 400ms ease-in-out;

    .list-item__main {
      position: relative;
      z-index: 1;
      visibility: visible;
      margin-top: 0;
      padding: 35px 0;
      border-bottom: 1px solid rgb(219, 219, 219);
      opacity: 1;
      transition: all 400ms ease-in-out;
    }
  }

  .list-item__action {
    // position: absolute;
    z-index: 0;

    // display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    max-height: 0px;
    width: 100%;
    visibility: hidden;
    margin: 0;
    padding: 0;

    color: $text;
    font-family: $lato;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
    opacity: 0;
    transition: all 400ms ease-in-out;
  }

  .list-item__loading,
  .list-item__successful,
  .list-item__error {
    display: none;
  }
  /* added when create is clicked */

  &.is-loading,
  &.is-successful,
  &.is-error {
    max-width: 100%;
    margin-top: 0;
    padding-top: 0;

    .list-item__content {
      max-height: 0px;
      border-bottom: none;
      transition: all 400ms ease-in-out;
    }

    .list-item__main {
      visibility: hidden;

      padding: 35px 30px;
      opacity: 0;

      * {
        pointer-events: none;
      }
    }

    .list-item__action {
      height: auto;
      max-height: 1000px;
      visibility: visible;
      margin: 0;
      opacity: 1;
    }
  }

  .list-item__loading {
    width: 100%;

    .list-item__loading-content {
      padding: 35px;

      text-align: center;
      background: rgb(242, 247, 249);
    }

    .list-item__loading-icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  /* added when create is clicked */

  &.is-loading {
    padding: 0;

    .list-item__action {
      .list-item__loading {
        display: block;

        .list-item__loading-icon {
          height: 175px;
          max-width: $max-width;
          margin: 0 auto;
          margin-top: 20px auto;

          text-align: center;
        }
      }
    }
  }
  /* added when response is recieved */

  &.is-successful {
    padding: 0;
    background: rgb(242, 247, 249);

    .list-item__action {
      position: relative;

      .list-item__loading {
        display: none;
      }

      .list-item__successful {
        display: flex;
        // align-items: center;
        justify-content: center;
        height: auto;
        max-width: $max-width;
        margin: 0 auto;
        padding: 35px 0;
      }

      .list-item__success-content {
        max-width: 380px;
        margin: 0 auto 0 20px;
        padding-left: 35px;

        @media ($bp-large-min) {
          margin: 0 auto 0 calc(33% - 20px);
        }
      }
    }
  }

  &.is-error {
    background: rgb(242, 247, 249);

    .list-item__action {
      .list-item__loading {
        display: none;
      }

      .list-item__loading {
        position: relative;
        display: none;
      }

      .list-item__error {
        display: flex;
        // align-items: center;
        justify-content: center;
        height: auto;
        max-width: $max-width;
        margin: 0 auto;
        padding: 35px 0;
      }

      .list-item__error-content {
        max-width: 80%;
        margin: 0 auto 0 20px;
        padding-left: 35px;

        .btn {
          margin-top: 10px;
        }

        @media ($bp-large-min) {
          margin: 0 auto 0 calc(10% - 20px);
        }
      }
    }
  }
}

.listing-item__detail-text {
  margin: 0 0 6px;

  font-weight: bold;

  &:first-child {
    margin-top: 47px;
  }

  &.listing-item__detail-text--red {
    color: rgb(220, 0, 0);
  }

  &.listing-item__detail-text--linethrough {
    margin-top: 24px;

    font-weight: normal;
    text-decoration: line-through;
  }
}
