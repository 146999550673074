@import "../../../scss/_imports.scss";

.listing {
  padding: 0;

  > .aba-container {
    padding-top: 15px;
  }
}

.fined__container {
  .page-banner {
    margin-bottom: 0;
  }
}

.sponsors {
  grid-template-columns: 1fr;
  grid-template-rows: auto; // required for IE11
  .container__col {
    min-width: 0; // hack to set a width and prevent blow out
  }

  @media ($bp-small-min) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto; // required for IE11
  }

  @media ($bp-medium-min) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

.sponsors__card {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-bottom: 50%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 232, 237);
  border-radius: 0px;
  box-shadow: 0px 2px 4px 0px rgba(166, 166, 166, 0.29);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    max-width: 100%;
    margin: 0;
    transform: translate(-50%, -50%);
  }

  @media ($bp-small-min) {
    padding-bottom: 50%;
  }

  @media ($bp-medium-min) {
    padding-bottom: 100%;
  }
}

.aba-container.sponsors {
  .aba-container__col {
    margin-top: 0;
  }
}

.results__header {
  display: block;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 40px;

  @media ($bp-medium-min) {
    display: flex;
  }

  p {
    height: 25px;
    width: 100%;

    color: rgb(103, 118, 139);
    font-family: $lato;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
  }
}

.aba__fined-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;

  label {
    flex-basis: 30%;
    height: 25px;
    width: 58px;

    font-family: $lato;
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
  }

  select {
    display: inline-block;
    flex-basis: 70%;
  }
}

.fin-ed-listing__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: all 400ms ease-in-out;

  &.is-loading {
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }

  .fin-ed-listing__loader-svg {
    position: relative;
    display: inline-block;
    margin-top: 0 auto;
  }
}
