@import "../../../scss/_imports.scss";

.sidebar-component {
  margin: 0 15px;
  padding: 30px 5px;

  &:first-child {
    padding: 0 5px 30px;

    h2 {
      margin-top: 0;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgb(219, 219, 219);
  }
}

.sidebar-component__title {
  margin-bottom: 22px;

  color: rgb(78, 86, 101);
  font-family: $playfair;
  font-size: 34px;
  font-weight: bold;
}

.sidebar-component__dropdown {
  margin-bottom: 25px;
}

.sidebar-component__price-item {
  height: 25px;
  margin-bottom: 7px;

  color: rgb(78, 86, 101);
  font-family: $lato;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;

  &.sidebar-component__price-item--red {
    color: rgb(220, 0, 0);
  }

  a {
    color: rgb(21, 88, 151);
    text-decoration: underline;
  }

  &.sidebar-component__price-item--strike-through {
    font-weight: normal;
    text-decoration: line-through;
  }
}

.sidebar-component__button {
  margin: 32px 0 24px;
}

.sidebar-component__details,
.sidebar-component p {
  color: $gray;
  font-family: $lato;
  font-size: 18px;
  line-height: 25px;

  &.sidebar-component__details--italic {
    font-style: italic;
  }
}

.pricing-component__item {
  display: none;

  &.is-active {
    display: block;
  }
}
