.community-organizer-form {
  .btn {
    margin-top: 20px;

    & + .btn {
      margin-left: 10px;
    }

    &.btn-primary,
    &.btn-outline {
      min-width: auto;
      padding: 10px 30px;

      font-size: 14px;
      letter-spacing: 0;
    }
  }

  a:not(.btn) {
    font-style: normal;
  }
}
