.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.link--external {
  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: 8px;
  }
}
