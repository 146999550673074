@import "../../../../scss/_imports.scss";

.error-message {
  position: relative;
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 15px 15px 60px;

  color: rgb(78, 86, 101);
  font-family: $lato;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  word-break: break-word;
  background: rgb(251, 245, 230);

  border: 1px solid rgb(228, 185, 95);

  &::before {
    position: absolute;
    top: calc(50% - 16px);
    left: 15px;
    display: block;
    height: 32px;
    width: 32px;

    color: $white;
    font-family: $lato;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    background: #e4b95f;
    border-radius: 50%;
    content: "!";
  }

  &.is-active {
    display: block;
  }

  p {
    margin: 0;

    a {
      font-style: normal;
    }
  }
}
