@import "../../../scss/_imports.scss";

.aba-table {
  width: 100%;
  overflow: visible;
  border: 0;
  table-layout: fixed;

  p {
    margin: 0;
  }

  &.aba-table--alt-mobile {
    @media ($bp-large-max) {
      .aba-table__row {
        min-height: 40px;
        width: 100%;
        padding: 16px 0;

        &:nth-child(odd) {
          background-color: $off-white;
        }

        > .aba-table__cell:not(.mobile-hidden) {
          display: table;
          width: 100%;

          .btn {
            margin: 16px 0;
          }

          .aba-table__cell {
            display: table-cell;
            width: 50%;
            padding-left: 16px;

            vertical-align: middle;
          }
        }
      }

      .mobile-hidden {
        display: none;
      }

      .desktop-hidden {
        display: table-cell;
      }
    }
  }

  @media ($bp-large-max) {
    .mobile-hidden {
      display: none;
    }

    .desktop-hidden {
      display: table-cell;
    }
  }

  @media ($bp-large-min) {
    .mobile-hidden {
      display: table-cell;
    }

    .desktop-hidden {
      display: none;
    }
  }
}

.aba-table__row {
  display: block;
  padding: 10px 0;

  vertical-align: middle;

  &:last-child {
    padding-bottom: 20px;
  }

  &:first-child {
    display: none;

    @media ($bp-large-min) {
      display: table-row;
    }
  }

  @media ($bp-large-min) {
    display: table-row;
  }
}

.aba-table__cell {
  padding: 0 0 10px;

  vertical-align: middle;
  border: 0;
  // border-bottom: 1px solid #c6cacb;

  p {
    display: flex;
  }

  a {
    display: inline-block;
    padding: 0 10px;

    &:first-of-type {
      margin-left: auto;
    }
  }

  div {
    // min-height: 40px;
    padding: 10px 0;

    vertical-align: middle;
  }

  @media ($bp-small-max) {
    font-size: 14px;
  }
}

.aba-table__cell--heading {
  padding: 0;

  font-family: $playfair;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  text-transform: capitalize;
  background: none;

  @media ($bp-large-min) {
    padding: 40px 0 16px;

    font-size: 18px;
  }
}
