.banker-pairing-form {
  margin-bottom: 20px;

  .btn {
    margin-top: 20px;

    & + .btn {
      margin-left: 10px;
    }

    &.btn-primary,
    &.btn-outline {
      min-width: auto;
      padding: 10px 40px;

      font-size: 14px;
      letter-spacing: 0;
    }
  }

  a:not(.btn) {
    font-style: normal;
  }

  .banker-pairing-form__data {
    ul {
      margin: 0;
      margin-bottom: 20px;
      list-style: disc;
    }

    & > ul {
      padding: 0;
      list-style: none;
    }

    li {
      &::before {
        display: none;
      }
    }

    p,
    li {
      margin: 0;

      font-size: 16px;
      line-height: 24px;
    }
  }

  .aba-form__radio-group {
    margin-bottom: 0;
  }

  input.form-control {
    max-width: 100%;
  }

  .aba-form__field {
    & div {
      margin-left: 22px;
    }
  }

  p {
    display: inline;
  }
}
