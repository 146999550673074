@import "../../../scss/_imports.scss";

.profile-listing {
  position: relative;

  .list-item__btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .btn {
      width: 100%;
      max-width: 311px;
    }
  }
}

.profile-listing__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  transition: all 400ms ease-in-out;

  &.is-loading {
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }

  .profile-listing__loader-svg {
    position: relative;
    display: inline-block;
    margin-top: 0 auto;
  }
}

.profile-listing .aba-container {
  a {
    font-style: normal;
  }
}

.profile-listing__link-container.aba-container {
  padding-top: 34px;

  text-align: right;
}
