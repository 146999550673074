@import "../../../../scss/_imports.scss";

.checkbox-group {
  padding: 0;
  border: 0;

  legend {
    padding: 0;
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  max-width: max-content;

  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  cursor: pointer;

  label {
    font-weight: normal;
  }

  input {
    display: inherit;
    height: 0;
    width: 0;
    opacity: 0;

    &:checked ~ .checkbox__custom::after {
      top: 1px;
      left: 1px;
      height: 5px;
      width: 8px;
      border-bottom: 2px solid;
      border-left: 2px solid;
      opacity: 1;
      transform: rotate(-45deg);
    }

    // disabled state
    &:disabled ~ .checkbox__custom {
      border-color: #ccc;
    }
    // show dim checkmark for disabled and checked
    &:disabled:checked ~ .checkbox__custom::after {
      color: #ccc;
    }

    &:focus ~ .checkbox__custom {
      outline-color: highlight; // highlight is a browser specific css color
      outline-offset: 1px;
      outline-style: solid;
      outline-width: 2px;
    }
  }

  .checkbox input[type="checkbox"] {
    opacity: 0;
  }

  .checkbox__custom {
    position: relative;
    display: block;
    height: 12px;
    width: 12px;
    margin-right: 12px;
    border: 1px solid #505050;
    border-radius: 2px;

    &::after {
      top: 3px;

      color: #505050;
    }

    &::before {
      height: 16px;
      width: 16px;

      color: #505050;
      opacity: 0.5;
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      opacity: 1;
    }
  }
}
