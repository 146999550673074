.aba-form {
  display: flex;
  flex-direction: row;
}

.aba-form__field-group {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  flex-shrink: 0;
}

.aba-form__label {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

label {
  margin-bottom: 0;

  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
}

legend {
  margin-bottom: 8px;

  font-size: 16px;
  line-height: 1.625em;

  * {
    margin-bottom: 0px;
  }
}

.aba-form__fields--inline {
  display: flex;

  & > * {
    margin-right: 20px;
  }
}

.aba-form__field {
  margin-bottom: 0.5rem;

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 23px;
  }

  .form-control {
    max-width: 300px;
  }

  textarea.form-control {
    max-width: 430px;
  }

  &.aba-form__field--invalid {
    color: red;

    input {
      border: 1px solid red !important;
    }

    select {
      color: red;
      border-color: red;
    }

    .checkbox__custom {
      border-color: red;
    }
  }
}

.aba-form__field--hidden {
  display: none;
}

.aba-form__error-message {
  color: red;
  font-size: 14px;
}

.aba-form__radio-group {
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 0;
  border: 0;

  legend {
    margin-bottom: 8px;
  }

  label {
    font-weight: normal;
    line-height: 1.625em;
  }

  input {
    margin-right: 10px;

    vertical-align: middle;
  }
}
