@import "../../../scss/_imports.scss";

.aba-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 466px;
  box-sizing: border-box;
  margin-bottom: 24px;
  padding: 35px 15px 15px;
  border: 1px solid #979797;

  .tag {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .aba-card__title {
    margin-top: 17px;
    margin-bottom: 11px;
  }

  .tag.tag--outline {
    margin: 0;
    border-color: rgb(78, 86, 101);
    border-width: 1px;

    span {
      color: rgb(78, 86, 101);
    }
  }

  .price {
    font-weight: bold;
  }

  .btn {
    bottom: 15px;
    left: 15px;
    min-width: 113px;
    margin: 0 auto 0 0;
  }

  .add-to-cart {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    height: 100%;

    .btn {
      margin: auto 0 0 auto;
    }
  }
}

.add-to-cart__error {
  position: relative;
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 15px 15px 15px 60px;

  color: rgb(78, 86, 101);
  font-family: $lato;
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  word-break: break-word;
  background: rgb(251, 245, 230);

  border: 1px solid rgb(228, 185, 95);

  &::before {
    position: absolute;
    top: calc(50% - 16px);
    left: 15px;
    display: block;
    height: 32px;
    width: 32px;

    color: $white;
    font-family: $lato;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    background: #e4b95f;
    border-radius: 50%;
    content: "!";
  }

  &.is-active {
    display: block;
  }

  p {
    margin: 0;

    a {
      font-style: normal;
    }
  }
}

.cart-items {
  padding-right: 35px;

  a {
    @include link;
  }
}
