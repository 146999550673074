@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.flashing-loader {
  height: 14px;
  width: 34px;
}

.flashing-loader circle {
  animation: 2s linear infinite blink;
  animation-fill-mode: both;
}

.flashing-loader circle:nth-child(2) {
  animation-delay: 0.3s;
}

.flashing-loader circle:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
}

.circle-loader {
  height: 40px;
  width: 40px;
  max-width: 100px;
  animation: 2s linear infinite spin;

  circle {
    display: block;
    animation: 1.4s ease-in-out infinite circle-animation;
    fill: transparent;
    stroke: #2f3d4c;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-linecap: round;
    stroke-width: 10px;
    transform-origin: 50% 50%;
  }
}

.is-loading .overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
