@import "../../../scss/_imports.scss";

.subtotal-component {
  padding: 30px 80px 41px;
  border: 1px solid rgb(151, 151, 151);

  h2 {
    margin: 0 0 19px;
  }

  .btn {
    width: 100%;
  }

  .btn.btn-tertiary {
    margin-bottom: 31px;
  }
}

.subtotal-component__items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 42px;

  font-family: $lato;
  font-size: 18px;
  font-weight: bold;
}

.subtotal-component__data-protection {
  max-width: 90%;
  margin: 5px auto 30px;
  padding: 0;

  font-family: $lato;
  font-size: 18px;
  line-height: 33px;

  @media ($bp-x-large-max) {
    max-width: 80%;
  }

  @media (max-width: 767px) {
    max-width: 100%;

    text-align: center;
  }
}

.subtotal-component__data-protection-link {
  @include link;
}
