@import "../variables.scss";

@mixin link() {
  color: $primary;
  font-weight: bold;

  &:hover,
  &:focus {
    color: $c-link-hover;
  }
}
