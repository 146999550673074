.banker-registration-form {
  padding: 0;

  ul {
    li {
      margin-bottom: 0;

      font-size: 16px;
      line-height: 24px;
      list-style: disc;

      &::before {
        display: none;
      }
    }
  }

  .btn {
    margin-top: 20px;

    & + .btn {
      margin-left: 10px;
    }

    &.btn-primary,
    &.btn-outline {
      min-width: auto;
      padding: 10px 30px;

      font-size: 14px;
      letter-spacing: 0;
    }
  }

  .banker-pairing-form__user-data {
    p {
      margin-bottom: 0;
    }
  }
}
