@import "./_imports.scss";

.aba-table {
  width: 100%;
  overflow: visible;
  border: 0;

  &.aba-table--alt-mobile {
    @media ($bp-large-max) {
      .aba-table__row {
        width: 100%;

        &:nth-child(odd) {
          background-color: $off-white;
        }

        > .aba-table__cell:not(.mobile-hidden) {
          display: table;
          width: 100%;

          &:last-child {
            // .aba-table__cell:first-child {
            //   display: none;
            // }

            .aba-table__cell {
              //   width: 100%;
            }
          }

          .btn {
            margin: 16px 0;
          }

          .aba-table__cell {
            display: table-cell;
            width: 50%;
            padding-left: 16px;
          }
        }
      }

      .mobile-hidden {
        display: none;
      }

      .desktop-hidden {
        display: table-cell;
      }
    }
  }

  @media ($bp-large-max) {
    .mobile-hidden {
      display: none;
    }

    .desktop-hidden {
      display: table-cell;
    }
  }

  @media ($bp-large-min) {
    .mobile-hidden {
      display: table-cell;
    }

    .desktop-hidden {
      display: none;
    }
  }
}
