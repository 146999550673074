@import "../../../scss/_imports.scss";

.fdic {
  .is-hidden {
    display: none;
    visibility: hidden;
  }

  .container {
    max-width: ($max-content-width + ($gutter * 2));
    box-sizing: border-box;
    margin-right: auto;
    margin-left: auto;
    outline: 0;

    @media ($bp-small-max) {
      padding-right: $gutter-mobile;
      padding-left: $gutter-mobile;
    }
  }

  .container-fluid {
    max-width: 100%;
    margin: 0;
    padding: 0 $gutter;

    &:focus {
      outline: 0;
    }
  }

  .container--padding-large {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .container--narrow {
    max-width: 840px;
  }

  .h2 {
    @include font("Playfair Display", 700, normal);

    font-size: 28px;
    line-height: 34px;

    @media (min-width: $bp-medium) {
      font-size: 34px;
      line-height: 38px;
    }
  }

  .playfair {
    font-family: "Playfair Display", serif;
  }

  .bold {
    font-weight: bold;
  }

  .error {
    position: absolute;
    top: 100%;
    left: 0;
    flex-basis: 100%;
    margin-top: 5px;

    color: white;
    font-size: 14px;
    text-align: left;

    &::before {
      display: inline-block;
      height: 22px;
      width: 22px;
      box-sizing: border-box;
      margin-right: 5px;
      padding: 2px 0;

      color: white;
      font-size: 14px;
      font-weight: 900;
      line-height: 20px;
      text-align: center;
      background: red;
      border-radius: 50%;
      content: "!";
    }
  }
}

.page-banner + .fdic {
  margin-top: -30px;
}

.fdic__banner {
  padding: 30px 12px 60px;
  background: #4e5665;
}

.fdic__lookup {
  * {
    box-sizing: border-box;
  }
}

.fdic__form {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;

    font-size: 16px;
    line-height: 1;
  }
}

.fdic__input-group {
  @include zero();
  position: relative;
  display: flex;
  flex-basis: 80%;
  flex-direction: row;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  justify-content: space-around;

  @media screen and (min-width: $bp-medium) {
    flex-basis: 80%;
    margin: 0;
  }

  @media screen and (min-width: $bp-small) {
    flex-basis: 80%;
    width: auto;
    margin: 0;
    margin-bottom: 0;

    text-align: right;
  }
}

.fdic__input {
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 40px;
  width: calc(100% - 200px);
  padding: 12px;
  appearance: none;
  border: 0;
  border-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
}

.fdic__submit {
  flex-basis: 4%;
  flex-grow: 1;
  flex-shrink: 1;
  height: 40px;
  width: 100px;
  padding: 12px;

  color: #313640;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  appearance: none;
  background: #ebb955;
  border: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 5px;
  transition: all 0.4s ease-in-out;

  &:hover {
    background: #fed259;
  }
}

.fdic__label {
  display: inline-flex;
  align-self: flex-start;
  flex-basis: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  margin: 0;
  padding: 0 0 5px;

  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.fdic__results {
  padding-bottom: 60px;
  background: #f3f4f7;
}

.fdic__result-item {
  padding: 4px 20px 24px;
  background: #fff;
  border-top: 8px solid #ecb84c;

  h2 {
    margin: 12px 0px;
    padding-bottom: 10px;

    font-size: 28px;
    font-style: underline;
    line-height: 34px;
    border-bottom: 1px solid #eee;
  }

  span {
    display: block;
    width: auto;
    padding: 2px 0px 8px;

    @media screen and (min-width: $bp-medium) {
      display: inline-block;
      padding: 2px 0px;
    }
  }

  .fdic__result-label {
    display: block;
    width: 175px;
    padding: 2px 0px;

    font-family: "Playfair Display", serif;
    font-weight: 700;

    @media screen and (min-width: $bp-medium) {
      display: inline-block;
      padding: 2px 0px;
    }
  }

  @media screen and (min-width: $bp-small) {
    padding: 12px 32px 32px;
  }
}
