@import "../../../scss/_imports.scss";

.pricing-component {
  margin-top: 0 14px;
  padding: 40px;
  border-bottom: 1px solid rgb(219, 219, 219);
}

.pricing-component__title {
  height: 92px;
  width: 318px;
  margin-bottom: 22px;

  color: rgb(78, 86, 101);
  font-family: $playfair;
  font-size: 34px;
  font-weight: bold;
}

.pricing-component__price-item {
  height: 25px;
  margin-bottom: 7px;
  //   width: 209px;

  color: rgb(78, 86, 101);
  font-family: $lato;
  font-size: 18px;
  font-weight: bold;
  line-height: 25px;

  &.pricing-component__price-item--red {
    color: rgb(220, 0, 0);
  }

  a {
    color: rgb(21, 88, 151);
    text-decoration: underline;
  }

  &.pricing-component__price-item--strike-through {
    font-weight: normal;
    text-decoration: line-through;
  }
}

.pricing-component__button {
  margin: 32px 0 24px;
}

.pricing-component__details {
  color: $gray;
  font-family: $lato;
  font-size: 18px;
  line-height: 25px;

  &.pricing-component__details--italic {
    font-style: italic;
  }
}
