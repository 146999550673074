@import "../../../scss/_imports.scss";

.icon {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
}

.btn__icon {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  margin-left: 0;
}

.icon--after {
  flex-direction: row-reverse;

  .btn__icon {
    margin-right: 0;
    margin-left: 10px;
  }
}

.btn.btn-outline {
  padding: 10px 20px;

  color: $primary;
  letter-spacing: 0px;
  background-color: transparent;
  border: 2px solid $primary;
}

.btn.btn-tertiary {
  padding: 4px 0;

  color: $primary;
  line-height: 33px;
  letter-spacing: 1px;
  // text-transform: none;
}

.btn {
  &:focus {
    /* overriding legacy styles */
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5) !important;
  }

  &.is-active {
    color: $white;
    background: $primary;
  }

  &.is-loading,
  &.btn-primary.is-loading {
    color: #979797;
    background: #cecece;
    border-color: #cecece;
    cursor: default;
    pointer-events: none;

    &:hover,
    &:focus {
      color: #979797;
      /* overriding legacy code */
      background: #cecece !important;
      box-shadow: 0 0 0 0.2rem $off-white !important;
    }
  }

  &.btn-not-available {
    color: $text;
    background: $white;
    border: 1px dashed rgb(78, 86, 101);
    cursor: default;
    pointer-events: none;
  }
}
