@import "../../../scss/_imports.scss";

.results__item {
  margin-bottom: 40px;
  border: 1px solid rgb(229, 232, 237);
  box-shadow: 0px 2px 4px 0px rgba(166, 166, 166, 0.29);

  .results__accordion-button {
    padding: 32px 15px;

    @media ($bp-large-min) {
      padding: 32px 45px;
    }
  }

  .results__accordion-panel {
    max-height: 0px;
    overflow: hidden;
    visibility: hidden;
    padding: 0;
    transition: all 0.35s ease-out;

    @media ($bp-large-min) {
      padding: 0 45px;
    }
  }

  .aba-table {
    margin: 0;
    padding: 40px 0 25px;
    border-top: 2px solid rgb(229, 232, 237);
  }

  .results__title {
    margin: 0;

    color: $primary;
  }

  &.is-open {
    .results__accordion-panel {
      max-height: 1000px;
      visibility: visible;
      overflow-y: auto;
      transition: all 0.55s ease-in;
    }

    .results__accordion-icon {
      &::after {
        opacity: 0;
      }
    }
  }
}

.results__accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  text-align: left;
  background: none;
  border: 0;
}

.results__accordion-icon {
  position: relative;
  display: block;

  height: 35px;
  width: 35px;
  box-sizing: content-box;
  background: rgb(243, 244, 247);
  border-radius: 50%;

  &::before {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 8px);
    display: block;
    height: 2px;
    width: 16px;
    background: $text;
    content: " ";
  }

  &::after {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 1px);
    display: block;
    height: 16px;
    width: 2px;
    background: $text;
    content: " ";
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
  }
}
