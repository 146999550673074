@import "../../../scss/_imports.scss";

.icon-success {
  position: relative;

  &::before {
    position: absolute;
    top: 6px;
    left: 0;
    z-index: 1;
    height: 20px;
    width: 20px;
    background: #058218;
    border: 1px solid $white;
    border-radius: 50%;
    content: "";
  }

  &::after {
    position: absolute;
    top: 12px;
    left: 6px;
    z-index: 2;
    height: 0.25rem;
    width: 0.5rem;
    border: 1px solid $white;
    border-width: 0 0 1px 1px;
    content: "";
    transform: rotate(-45deg);
  }

  &.unlisted {
    padding-left: 32px;
  }
}

.icon-error {
  position: relative;

  &::before {
    position: absolute;
    top: 12px;
    left: 0px;
    z-index: 1;
    display: inline-block;
    height: 22px;
    width: 22px;
    box-sizing: border-box;
    margin-right: 5px;
    padding: 2px 0;

    color: white;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    text-align: center;
    content: "!";
  }

  &::after {
    position: absolute;
    top: 6px;
    left: -3px;
    z-index: 0;
    height: 0;
    width: 0;
    border-bottom: 30px solid red;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    content: "";
  }
}

.page-banner {
  &.page-banner--narrow {
    padding: 14px 0;

    .breadcrumbs {
      margin-bottom: 0 !important;

      li {
        display: inline-block;
      }
    }
  }
}
