@import "../../../../../scss/_imports.scss";

.aba-input__typeahead {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 4;
  max-height: 500px;
  width: 100%;
  visibility: hidden;
  padding: 0;
  background: $white;
  border: 1px solid #a9d1ff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 96, 185, 0.2);
  overflow-y: auto;

  .aba-input__typeahead-opt:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.is-visible {
    visibility: visible;
  }
}

.fined__form-input {
  position: relative;
  z-index: 5;
  background: white;
}

input.form-control.fined__form-input {
  padding-right: 50px;

  &.is-open {
    border-bottom-color: transparent !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.aba-input__typeahead-container {
  position: relative;
  flex-basis: 50%;
  max-width: 410px;
}

.aba-input__typeahead-opt {
  padding: 10px 15px;

  font-size: 16px;
  border-bottom: 1px solid #f3f3f3;
}

.aba-btn.aba-btn--clear {
  position: absolute;

  top: 2px;
  right: 2px;
  z-index: 5;
  height: 46px;
  width: 40px;

  color: #909090;
  background: white;
  border: 0;

  transition: color 250ms ease-in-out;

  &:hover,
  &:focus {
    color: $c-link-hover;
  }

  &.is-hidden {
    display: none;
  }

  .visuallyhidden {
    visibility: hidden;
  }
}

.fined__form {
  .fined__form-label {
    display: block;
  }
}

.fined__form-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .fined__form-label {
    flex-basis: 100%;
    margin: 8px 0;
  }

  .aba-input__typeahead-container {
    position: relative;
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 15px;

    @media ($bp-medium-min) {
      flex-basis: 50%;
      max-width: 410px;
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  .fined__form-submit {
    display: block;
    flex-basis: 100%;
    width: 100%;
    margin-top: 0;

    @media ($bp-medium-min) {
      flex-basis: 50%;
      max-width: 120px;
      margin-top: 0;
    }
  }
}
