@import "./_imports.scss";

$container-bottom-space: 60px;
$container-top-level-spacing: 35px;
$container-content-max: 1030px;
$container-gutter: 30px;
$container-gutter-mobile: 16px;

.aba-container {
  position: relative;
  padding-right: $container-gutter;
  padding-left: $container-gutter;

  @media ($bp-large-max) {
    padding-right: $container-gutter-mobile;
    padding-left: $container-gutter-mobile;
  }
  // add space between containers
  // all containers immediately after containers
  // bleed containers with background currently have style blocks above them

  & + .aba-container,
  & + style + .aba-container {
    margin-top: $container-top-level-spacing;
  }

  &:not(&--bleed) .aba-container {
    max-width: $container-content-max;
    margin-top: $container-top-level-spacing;
    padding-right: 0;
    padding-left: 0;
  }

  .aba-container__col + .aba-container__col {
    @media ($bp-large-max) {
      margin-top: $container-top-level-spacing;
    }
  }

  &.aba-container--divider {
    .aba-container__col + .aba-container__col {
      @media ($bp-large-min) {
        border-left: 1px solid #dbdbdb;
      }
    }
  }

  a:not(.btn) {
    color: $primary;
    font-family: $lato;
    font-style: italic;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: #09243e;
    }
  }
}

.aba-container--light-gray {
  background: rgb(243, 244, 247);
}

.aba-container--sibling-flush + .aba-container {
  margin-top: 0;
}

.aba-container--bleed {
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;

  & > .container__col {
    position: relative;
  }
}

.aba-container--100,
.aba-container--70-30,
.aba-container--60-40,
.aba-container--50-50,
.aba-container--30-70 {
  max-width: ($container-content-max + ($container-gutter * 2));
  margin-right: auto;
  margin-left: auto;
}

.aba-container--70-30,
.aba-container--60-40,
.aba-container--50-50,
.aba-container--30-70,
.aba-container--30-30-30 {
  @media ($bp-large-min) {
    display: grid;
    grid-gap: 30px;

    .aba-container__col {
      min-width: 0; // hack to set a width and prevent blow out
    }
  }
}

// 2/3 - 1/3

.aba-container--70-30 {
  @media ($bp-large-min) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

.aba-container--60-40 {
  @media ($bp-large-min) {
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto; // required for IE11
  }
}

.aba-container--50-50 {
  @media ($bp-large-min) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

// 1/3 - 2/3

.aba-container--30-70 {
  @media ($bp-large-min) {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto; // required for IE11
  }
}

.aba-container--30-30-30 {
  @media ($bp-large-min) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto; // required for IE11
  }
}

.aba-container--padding-b-sm {
  padding-bottom: 30px;
}

.aba-container--padding-b-lg {
  padding-bottom: 60px;
}

.aba-padding-m {
  padding: 35px 0;
}
