@import "../../../scss/_imports.scss";

.utility-nav__cart {
  position: relative;
  list-style: none;
}

.utility-nav__cart-count {
  position: absolute;
  top: -10px;
  right: -5px;
  display: none;
  height: 13px;
  min-width: 13px;
  margin: 0;
  padding: 2px 3px;

  color: $white;

  font-size: 9px;
  font-weight: 700;
  line-height: 9px;
  text-align: center;
  background: $primary;
  border-radius: 6px;
}
